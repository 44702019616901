const defaultState = {
  active: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_PARTNER':
      return Object.assign({}, state, {
        active: action.partner
      })
    default:
      return state
  }
}
