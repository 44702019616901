import { isProductionEnvironment, isLocalEnvironment } from './utils/getEnvironments'

let intClientID = 'XkXarT8uDVP720EjiDm61AfEUS3uIYgP'
let intApiUrl = 'https://xrgkbnvu51.execute-api.us-east-1.amazonaws.com/red'
let intAudience = 'https://red-portal-api.chain.io'

if (isProductionEnvironment()) {
  intClientID = '242TPDRauijoGJ9ObaNpmWlODJkPwHSP'
  intApiUrl = 'https://9u4fn4zkci.execute-api.us-east-1.amazonaws.com/production'
  intAudience = 'https://portal-api.chain.io'
}

if (isLocalEnvironment() && import.meta.env.VITE_USE_LOCAL_API) {
  intClientID = 'XkXarT8uDVP720EjiDm61AfEUS3uIYgP'
  intApiUrl = 'http://localhost:4000/red'
}

const getIdToken = () => localStorage.getItem('idToken')

const generateHeadersWithAuth = (additionalHeaders = {}) => {
  const token = getIdToken()
  if (!token) {
    return additionalHeaders
  }
  return Object.assign(
    { Authorization: `Bearer ${token}` },
    additionalHeaders
  )
}

const clientID = intClientID
const apiUrl = intApiUrl
const audience = intAudience

export {
  clientID,
  apiUrl,
  audience,
  generateHeadersWithAuth,
  getIdToken
}
