import React, { Suspense, useEffect } from 'react'
import { Router, Switch } from 'react-router-dom'
// upgrade script instructions: https://github.com/remix-run/react-router/discussions/8753
import { CompatRouter, CompatRoute } from 'react-router-dom-v5-compat'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga4'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import LoadingWrapper from './elements/LoadingWrapper'
import Auth0WithNavigate from './elements/core/Auth0WithNavigate'
import ProfileData from './elements/core/ProfileData'
import lazyWithRetry from './utils/lazyWithRetry'

const GraphiQL = lazyWithRetry(() => import('./pages/GraphiQL'))
const NotFound = lazyWithRetry(() => import('./NotFound'))
const CompanySelect = lazyWithRetry(() => import('./pages/CompanySelect'))
const CompanyPulse = lazyWithRetry(() => import('./pages/CompanyPulseV2'))
const CompanyMetrics = lazyWithRetry(() => import('./pages/CompanyMetrics'))
const CompanyFlowReportList = lazyWithRetry(() => import('./pages/flow_usage/FlowUsageReportList'))
const CompanySettings = lazyWithRetry(() => import('./pages/CompanySettings'))
const CompanySettingsCarrierAPI = lazyWithRetry(() => import('./pages/companySettings/CarrierAPI'))
const CompanySettingsCompanyProfile = lazyWithRetry(() => import('./pages/companySettings/CompanyProfile'))
const CompanySettingsAs2Settings = lazyWithRetry(() => import('./pages/companySettings/WorkspaceAS2Profiles'))
const CompanySettingsUsers = lazyWithRetry(() => import('./pages/companySettings/Users'))
const CompanySettingsSFTPUsers = lazyWithRetry(() => import('./pages/companySettings/SFTPUsers'))
const CompanySettingsSFTPLauncher = lazyWithRetry(() => import('./pages/companySettings/SFTPLauncher'))
const CompanySettingsHostSystems = lazyWithRetry(() => import('./pages/companySettings/HostSystems'))
const CompanySettingsHostSystemVerify = lazyWithRetry(() => import('./pages/companySettings/HostSystemVerify'))
const CompanySettingsWorkspaceTemplates = lazyWithRetry(() => import('./pages/companySettings/workspaceTemplates/List'))
const CompanySettingsWorkspaceTemplateEdit = lazyWithRetry(() => import('./pages/companySettings/workspaceTemplates/Edit'))
const CompanySettingsNotifiers = lazyWithRetry(() => import('./pages/companySettings/Notifiers'))
const CompanySettingsTradingPartners = lazyWithRetry(() => import('./pages/companySettings/TradingPartners'))
const CompanySettingsTradingPartnerEdit = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerEdit'))
const CompanySettingsTradingPartnerNew = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerNew'))
const CompanySettingsTradingPartnerView = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerView'))
const CompanySettingsTradingPartnerTemplates = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerTemplates'))
const CompanySettingsTradingPartnerCarrierApiKeyNew = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerCarrierApiKeyNew'))
const CompanySettingsTradingPartnerCarrierApiKeyEdit = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerCarrierApiKeyEdit'))
const CompanySettingsTradingPartnerConnectionEdit = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerConnectionEdit'))
const CompanySettingsTradingPartnerConnectionNew = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerConnectionNew'))
const CompanySettingsTradingPartnerConnectionVerify = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerConnectionVerify'))
const CompanySettingsTradingPartnerConnectionQuery = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerConnectionQuery'))
const CompanySettingsTradingPartnerFlowConfigurationEdit = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerFlowConfigurationEdit'))
const CompanySettingsTradingPartnerFlowConfigurationNew = lazyWithRetry(() => import('./pages/companySettings/TradingPartnerFlowConfigurationNew'))
const CompanySettingsLicense = lazyWithRetry(() => import('./pages/companySettings/License'))
const CompanySettingsInvoices = lazyWithRetry(() => import('./pages/companySettings/Invoices'))
const CompanySettingsRemoteApapter = lazyWithRetry(() => import('./pages/companySettings/RemoteAdapterApiKey'))
const CompanySettingsPortalApiKeys = lazyWithRetry(() => import('./pages/companySettings/PortalApiKeys'))
const FlowExecutionDetails = lazyWithRetry(() => import('./pages/FlowExecutionDetails'))
const OAuthRedirect = lazyWithRetry(() => import('./pages/OAuthRedirect'))
const TradingPartnerDashboard = lazyWithRetry(() => import('./pages/TradingPartnerDashboard'))
const TradingPartnerSearch = lazyWithRetry(() => import('./pages/TradingPartnerSearch'))
const CapiQuotes = lazyWithRetry(() => import('./pages/CapiQuotes'))
const CapiQuoteDetails = lazyWithRetry(() => import('./pages/CapiQuoteDetails'))
const CapiQuoteFiles = lazyWithRetry(() => import('./pages/CapiQuoteFiles'))
const Callback = lazyWithRetry(() => import('./Auth/Callback'))
const NewCompany = lazyWithRetry(() => import('./pages/NewCompany'))
const Error = lazyWithRetry(() => import('./pages/Error'))
const MultiCompanyActions = lazyWithRetry(() => import('./pages/multiCompany/MultiCompanyActions'))
const MultiCompanyUsers = lazyWithRetry(() => import('./pages/multiCompany/Users'))
const EAdaptorQuery = lazyWithRetry(() => import('./pages/companySettings/EAdaptorQuery'))
const EmbeddedChainEntryPage = lazyWithRetry(() => import('./components/EmbeddedChain'))
const SolutionLibrary = lazyWithRetry(() => import('./pages/tradingPartner/SolutionLibrary'))
const SFTPJobConfiguration = lazyWithRetry(() => import('./pages/companySettings/SFTPJobConfiguration'))
const SFTPJobEventLog = lazyWithRetry(() => import('./pages/companySettings/SFTPEventLog'))
const WorkspaceNotFound = lazyWithRetry(() => import('./pages/errors/WorkspaceNotFound'))
const IntegrationNotFound = lazyWithRetry(() => import('./pages/errors/IntegrationNotFound'))
const RemoteAuthorizationError = lazyWithRetry(() => import('./pages/errors/RemoteAuthorizationError'))
const UploadPageUpload = lazyWithRetry(() => import('./pages/uploadPage/Upload'))
const RedirectFlowDetailsWithoutTradingPartner = lazyWithRetry(() => import('./pages/RedirectFlowDetailsWithoutTradingPartner'))

const history = createBrowserHistory()
history.listen((location) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
})

function withTokenUpdate(component) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const doWork = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently()
        console.log('writing id token')
        localStorage.setItem('idToken', token)
      }
    }
    doWork()
  }, [isAuthenticated, getAccessTokenSilently])

  return component
}

function ProtectedRoute({ component, ...rest }) {
  const c = withTokenUpdate(withAuthenticationRequired(component, {
    onRedirecting: () => {
      localStorage.setItem('CHAINIO_LOGIN_REDIRECT', window.location.pathname + window.location.search)
      return (<LoadingWrapper loading center vCenter />)
    }
  }))
  return <CompatRoute {...rest} component={c} />
}

export default () => (
  <Suspense fallback={<LoadingWrapper loading center vCenter />}>
    <Auth0WithNavigate>
      <ProfileData>
        <Router history={history}>
          <CompatRouter>
            <Switch>
              <CompatRoute exact path="/auth_callback" component={Callback} />
              <CompatRoute exact path="/cu/:company_short_code/:custom_path" component={UploadPageUpload} />
              <ProtectedRoute exact path="/ecp" component={EmbeddedChainEntryPage} />
              <ProtectedRoute exact path="/c/new" component={NewCompany} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid" component={TradingPartnerDashboard} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/search" component={TradingPartnerSearch} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/library" component={SolutionLibrary} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/templates" component={CompanySettingsTradingPartnerTemplates} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/f/:flow_execution_uuid" component={FlowExecutionDetails} />
              {/* keep these two routes for backwards compatibility from when files & logs were on their own pages */}
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/f/:flow_execution_uuid/logs" component={FlowExecutionDetails} />
              <ProtectedRoute exact path="/c/:company_uuid/tp/:partner_uuid/f/:flow_execution_uuid/files" component={FlowExecutionDetails} />
              <ProtectedRoute exact path="/c/:company_uuid/cq" component={CapiQuotes} />
              <ProtectedRoute exact path="/c/:company_uuid/cq/:quote_uuid" component={CapiQuoteDetails} />
              <ProtectedRoute exact path="/c/:company_uuid/cq/:quote_uuid/files" component={CapiQuoteFiles} />
              <ProtectedRoute exact path="/c/:company_uuid/flow_usage_reports" component={CompanyFlowReportList} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/notifiers" component={CompanySettingsNotifiers} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/capi" component={CompanySettingsCarrierAPI} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/users" component={CompanySettingsUsers} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/sftpusers" component={CompanySettingsSFTPUsers} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/sftp-launcher" component={CompanySettingsSFTPLauncher} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/sftp-launcher/:job_uuid/edit" component={SFTPJobConfiguration} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/sftp-launcher/:job_uuid/:job_execution_uuid" component={SFTPJobEventLog} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/sftp-launcher/new" component={SFTPJobConfiguration} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/hs/:host_system_uuid/verify" component={CompanySettingsHostSystemVerify} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/hs/:host_system_uuid/eadaptorquery" component={EAdaptorQuery} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/hs" component={CompanySettingsHostSystems} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/profile" component={CompanySettingsCompanyProfile} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/as2" component={CompanySettingsAs2Settings} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/open_connect" component={CompanySettingsRemoteApapter} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/portal_api_keys" component={CompanySettingsPortalApiKeys} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/new" component={CompanySettingsTradingPartnerNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/edit" component={CompanySettingsTradingPartnerEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc/new/:flow_type/:flow_name" component={CompanySettingsTradingPartnerFlowConfigurationNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc/new_licensed_flow/:integration_identifier" component={CompanySettingsTradingPartnerFlowConfigurationNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc/new" component={CompanySettingsTradingPartnerFlowConfigurationNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc/:flow_uuid/v/:version_millis" component={CompanySettingsTradingPartnerFlowConfigurationEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc/:flow_uuid" component={CompanySettingsTradingPartnerFlowConfigurationEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/con/new" component={CompanySettingsTradingPartnerConnectionNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/con/:endpoint_uuid/edit" component={CompanySettingsTradingPartnerConnectionEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/con/:endpoint_uuid/verify" component={CompanySettingsTradingPartnerConnectionVerify} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/con/:endpoint_uuid/eadaptorquery" component={CompanySettingsTradingPartnerConnectionQuery} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/capi/new" component={CompanySettingsTradingPartnerCarrierApiKeyNew} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/capi/:key_uuid/edit" component={CompanySettingsTradingPartnerCarrierApiKeyEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/capi" component={CompanySettingsTradingPartnerView} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/fc" component={CompanySettingsTradingPartnerView} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid/con" component={CompanySettingsTradingPartnerView} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp/:partner_uuid" component={CompanySettingsTradingPartnerView} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/tp" component={CompanySettingsTradingPartners} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/invoices" component={CompanySettingsInvoices} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/license" component={CompanySettingsLicense} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/wst/:template_uuid" component={CompanySettingsWorkspaceTemplateEdit} />
              <ProtectedRoute exact path="/c/:company_uuid/settings/wst" component={CompanySettingsWorkspaceTemplates} />
              <ProtectedRoute exact path="/c/:company_uuid/settings" component={CompanySettings} />
              <ProtectedRoute exact path="/c/:company_uuid" component={CompanyPulse} />
              <ProtectedRoute exact path="/c/:company_uuid/f/:flow_execution_uuid" component={RedirectFlowDetailsWithoutTradingPartner} />
              <ProtectedRoute exact path="/c/:company_uuid/metrics" component={CompanyMetrics} />
              <ProtectedRoute exact path="/multic/users" component={MultiCompanyUsers} />
              <ProtectedRoute exact path="/multic" component={MultiCompanyActions} />
              <ProtectedRoute exact path="/graphql" component={GraphiQL} />
              <ProtectedRoute exact path="/error/integration-not-found" component={IntegrationNotFound} />
              <ProtectedRoute exact path="/error/workspace-not-found" component={WorkspaceNotFound} />
              <ProtectedRoute exact path="/error" component={Error} />
              <ProtectedRoute exact path="/oauth_redirect" component={OAuthRedirect} />
              <CompatRoute exact path="/force-login" component={RemoteAuthorizationError} />
              <ProtectedRoute exact path="/" component={CompanySelect} />
              <ProtectedRoute component={NotFound} />
            </Switch>
          </CompatRouter>
        </Router>
      </ProfileData>
    </Auth0WithNavigate>
  </Suspense>
)
