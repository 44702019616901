const defaultState = {
  active: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_CARRIER_API_KEY':
      return Object.assign({}, state, { active: action.carrierApiKey })
    default:
      return state
  }
}
