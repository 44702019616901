const defaultState = {
  loadingCalls: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ADD_LOADING_CALL': {
      const lc = state.loadingCalls.slice(0)
      lc.push(action.callID)
      return Object.assign({}, state, { loadingCalls: Array.from(new Set(lc)) })
    }
    case 'REMOVE_LOADING_CALL': {
      const lcr = new Set(state.loadingCalls)
      lcr.delete(action.callID)
      return Object.assign({}, state, { loadingCalls: Array.from(new Set(lcr)) })
    }
    default:
      return state
  }
}
