/* eslint-disable max-len */
const defaultState = {
  all: null,
  active: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_HOST_SYSTEMS':
      // expects both the array of systems and the company id that they were retrieved for
      // this is important so if we get an empty array back we know it was for the right company
      return Object.assign({}, state, {
        all: action.hostSystems,
        company_uuid: action.company_uuid,
        active: action.company_uuid === state.company_uuid ? state.active : null // reset if company_uuid changed
      })
    case 'SET_ACTIVE_HOST_SYSTEM':
      // expects both the array of systems and the company id that they were retrieved for
      // this is important so if we get an empty array back we know it was for the right company
      return Object.assign({}, state, {
        active: action.hostSystem,
        company_uuid: action.company_uuid,
        all: action.company_uuid === state.company_uuid ? state.all : null // reset if company_uuid changed
      })
    default:
      return state
  }
}
