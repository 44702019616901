export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_COMPANY_LIST':
      return Object.assign({}, state, { list: action.companies })
    case 'SET_COMPANIES_WITH_USERS':
      return Object.assign({}, state, { companiesWithUsers: action.companies })
    case 'SET_ACTIVE_COMPANY':
      return Object.assign({}, state, { active: action.company })
    default:
      return state
  }
}
