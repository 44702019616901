import _ from 'lodash'

const defaultState = {
  byCompany: {}
}

const sftpUsersObject = (state, action) => {
  const bc = _.clone(state.byCompany)
  bc[action.companyUUID] = action.users
  return Object.assign({}, state, {
    byCompany: bc
  })
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SFTP_USERS':
      return sftpUsersObject(state, action)
    default:
      return state
  }
}
