const defaultState = {
  active: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_CONFIGURED_FLOWS':
      return {
        ...state,
        flows: action.flows
      }
    default:
      return state
  }
}
