const initialState = {
  jobs: null,
  jobEvent: null,
  sftpJob: null,
  allEvents: {
    events: null,
    metaData: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SFTP_JOBS':
      return {
        ...state,
        companyUUID: action.companyUUID,
        jobs: action.jobs,
        jobEvent: null,
        sftpJob: null
      }
    case 'SET_SFTP_JOB':
      return {
        ...state,
        sftpJob: action.sftpJob
      }
    case 'SET_ALL_SFTP_JOBS_EVENTS':
      return {
        ...state,
        allEvents: action.allEvents
      }
    case 'SET_SFTP_JOB_EVENT':
      return {
        ...state,
        jobEvent: action.jobEvent
      }
    case 'CLEAR_SFTP_LAUNCHER':
      return initialState
    default:
      return {
        ...state
      }
  }
}
