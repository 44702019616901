const defaultState = {
  integration: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_INTEGRATION_LICENSE_TYPES':
      return Object.assign({}, state, {
        integration: action.integrationLicenseTypes
      })
    default:
      return state
  }
}
