export default {
  Company: {
    keyFields: ['company_uuid']
  },
  TradingPartner: {
    keyFields: ['partner_uuid']
  },
  FlowExecution: {
    keyFields: ['invocation_uuid']
  },
  Memo: {
    keyFields: ['memo_uuid']
  },
  Milestone: {
    keyFields: ['milestone_uuid']
  },
  FlowSetup: {
    keyFields: ['flow_uuid']
  },
  FlowVersion: {
    keyFields: ['flow_uuid', 'version_timestamp']
  },
  FlowConfiguration: {
    keyFields: ['flow_uuid']
  },
  LogJob: {
    keyFields: ['job_uuid']
  }
}
