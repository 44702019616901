import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga4'

function ProfileData({ children }) {
  const { user } = useAuth0()

  useEffect(() => {
    if (!user?.email) return
    Sentry.configureScope((scope) => {
      scope.setUser({ user: user.email })
    })
    ReactGA.set({ userId: user.email })
  }, [user])

  return children
}

export default ProfileData
