/**
 * if the loading prop is truthy then the Loading component is returned, else the children prop is returned
 */
import { ProgressSpinner } from 'primereact/progressspinner'

function Loading({ style = {}, center, vCenter }) {
  const pStyle = {
    width: '50px',
    height: '50px',
    ...style
  }

  if (center) {
    pStyle.margin = 'auto'
    pStyle.display = 'block'
  }

  if (vCenter) {
    return (
      <div className="flex align-items-center justify-content-center h-screen">
        <div>
          <ProgressSpinner
            style={pStyle}
          />
        </div>
      </div>
    )
  }

  return (
    <ProgressSpinner
      style={pStyle}
    />
  )
}

function LoadingWrapper({
  loading, children, center, vCenter
}) {
  if (loading) return <Loading center={center} vCenter={vCenter} />
  return children
}

export default LoadingWrapper
