import _ from 'lodash'

const defaultState = {
  byCompany: {}
}

const remoteAdapterApiKeysObject = (state, action) => {
  const bc = _.clone(state.byCompany)
  bc[action.companyUUID] = action.keys
  return Object.assign({}, state, {
    byCompany: bc
  })
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_REMOTE_ADAPTER_API_KEYS':
      return remoteAdapterApiKeysObject(state, action)
    default:
      return state
  }
}
