import { combineReducers } from 'redux'
import companies from './companies'
import endpoints from './endpoints'
import flowConfiguration from './flowConfiguration'
import gui from './gui'
import hostSystems from './hostSystems'
import tradingPartners from './tradingPartners'
import carrierApiKeys from './carrierApiKeys'
import flowFilterOptions from './flowFilterOptions'
import sftpUsers from './sftpUsers'
import licenseTypes from './licenseTypes'
import remoteAdapterApiKeys from './remoteAdapterApiKeys'
import sftpLauncher from './sftpLauncher'

export default combineReducers({
  carrierApiKeys,
  companies,
  endpoints,
  flowConfiguration,
  gui,
  hostSystems,
  tradingPartners,
  flowFilterOptions,
  sftpUsers,
  licenseTypes,
  remoteAdapterApiKeys,
  sftpLauncher
})
